import React from 'react';
import Item from "./Item";


const Charts2 = (props) => {
    const {lastMonthReqs, thisMonthReqs, lastMonthReqs1, thisMonthReqs1, totalIntrPenalty} = props;
    const sumup = parseFloat(lastMonthReqs) + parseFloat(thisMonthReqs);
    const data = [lastMonthReqs > 0 ? parseInt(lastMonthReqs) : 1, thisMonthReqs > 0 ? parseInt(thisMonthReqs) : 1],
    subTitle1 = "Total Requests",
    subTitle2 = `${parseFloat(totalIntrPenalty).toLocaleString()}`,
    currency = "₦",
    showLabel = true,
    colors = ["#33539d", "#65cf6f"],
    radius = 130,
    hole = 75,
    stroke = 1,
    strokeWidth = 10;

    const currentDate = new Date();
    const thisMonth = currentDate.toLocaleString('default', { month: 'long' });

    currentDate.setMonth(currentDate.getMonth() - 1);
    // if (currentDate.getMonth() === 11) { // it start counting month from 0
    //   currentDate.setMonth(currentDate.getMonth() - 1);
    // }
    const lastMonth = currentDate.toLocaleString('default', { month: 'long' });


  return (

    <div className="card pb-xs-40">
      <div className="card-body pb-0 mb--20 mb-xs-0">
          <h4 className="card-title text-left"><i className="mdi mdi-chart-pie"></i> Loan Monthly Analysis</h4>
          <div className="analysis1 row text-left">
            <div className="col-6 mb-2">
              <h3>
                  &#8358;{parseFloat(lastMonthReqs).toLocaleString()}
              </h3>
              <h6 className="font-weight-normal mb-0">
                  {lastMonth}
                  <div className='mt-2' style={{ opacity:'0.7' }}>Interests <span style={{ color:'#5f6be3' }}> + penalty</span></div>
              </h6>
              <div className='mt-3' style={{ background:'#33539d', height:'4px', width:'70px', borderRadius:'10px' }}></div>
            </div>

            <div className="col-6 mb-2">
              <h3>  
                &#8358;{parseFloat(thisMonthReqs1).toLocaleString()}
              </h3>
              <h6 className="font-weight-normal mb-0">
                {thisMonth}
                <div className='mt-2' style={{ opacity:'0.7' }}>Interests <span style={{ color:'#5f6be3' }}> + penalty</span></div>
              </h6>
              <div className='mt-3' style={{ background:'#65cf6f', height:'4px', width:'70px', borderRadius:'10px' }}></div>
            </div>
          </div>
      </div>
      
      <div className="card-body p-0 text-center">
          <div className="mt-auto w-100">
            <div className="item pl-50 pr-30 pl-sm-50 pl-xs-50">
              <Item
                data={data}
                subTitle1={subTitle1}
                subTitle2={subTitle2}
                colors={colors}
                radius={radius}
                hole={hole}
                stroke={stroke}
                strokeWidth={strokeWidth}
                showLabel={showLabel}
                currency={currency}
              />
            </div>
          </div>
      </div>
    </div>
  );
};

export default Charts2;
