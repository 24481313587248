import { useForm } from "react-hook-form";

const namesValidation = {
    minLength: {
        value: 6,
        message: 'Enter a valid first and last name',
    },
    pattern: {
        value: /^[a-zA-Z-]{3,} [a-zA-Z-]{3,}$/, // names with at least 3 letters each. Eg. Chi Anthony
        message: "Enter a valid first and last name"
    }
}

const phoneValidation = {
    required: {
        value: true,
        message: 'Phone field is required',
    },
    minLength: {
        value: 6,
        message: 'Invalid phone format',
    },
    maxLength: {
        value: 11,
        message: 'Invalid phone format',
    },
    pattern: {
        value: /^[0-9+-]+$/,
        message: "Invalid phone format"
    }
}

const phoneValidationNoReqs = {
    minLength: {
        value: 6,
        message: 'Invalid phone format',
    },
    maxLength: {
        value: 11,
        message: 'Invalid phone format',
    },
    pattern: {
        value: /^[0-9+-]+$/,
        message: "Invalid phone format"
    }
}

const emailValidation = {
    required: {
        value: true,
        message: 'Email field is required',
    },
    pattern: {
        value: /\S+@\S+\.\S+/,
        message: "Email is not valid"
    }
}

const emailValidationRegEx = {
    pattern: {
        value: /\S+@\S+\.\S+/,
        message: "Email is not valid"
    }
}

const passwordValidation = {
    required: {
        value: true,
        message: 'Password field is required',
    },
    minLength: {
        value: 6,
        message: "Minimum of 6 characters required"
    }
}

const confirmPasswordValidation = {
    ...passwordValidation,
    required: {
        value: true,
        message: 'Confirm password field is required',
    },
}



export { phoneValidation, emailValidation, passwordValidation, confirmPasswordValidation, emailValidationRegEx, phoneValidationNoReqs, namesValidation };