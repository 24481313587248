import React, { useEffect } from "react";
import './notfound.css';
// import './bootstrap.min.css';
import { Link } from "react-router-dom";


export default function NotFound() {   
    useEffect(() => {document.title = "Not Found | Daniezo"}, []);
    
    
    return (
        <section className="page_404">
            <div className="wrap-error">
                <div className="d-flex align-items-center h-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-8 offset-sm-2 text-center text-white">
                                <h1 className=""><span>4</span><span>0</span><span>4</span></h1>
                                <h5 className="">Oops! Page not found</h5>
                                <p className="mb-4">we are sorry, but the page you requested was not found</p>
                                <Link className="btn btn-dark" to="/dashboard/">Go home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}