import { BrowserRouter } from 'react-router-dom';
import './App.css';
import RouterComponent from './Components/Routes/RouterComponent';
import { API_ROUTES } from './utils/constants';
import { useEffect, useState } from 'react';


function App() {
  // const [newNotify, setNewNotify] = useState(0);
  // const userData = JSON.parse(localStorage.getItem('userDataDani'));

  // console.log(userData);
  // console.log('4444');

  // const token = userData?.responseDetails?.hash || '';

  // const getNotifyCounts = async () => {
  //   try {
  //     const response = await fetch(API_ROUTES.NOTIFYCOUNTS, {
  //         method: "GET",
  //         headers: {'hashKey': token}
  //     });
  //     const responseJSON = await response.json();
      
  //     if (responseJSON.status.toString() === "200") {
  //         const responseData = responseJSON.data;
  //         console.log(responseData);
  //         const counts = parseInt(responseData) >= 9 ? `${responseData}+` : responseData;
  //         setNewNotify(counts);
  //     }
  //   } catch (error) {
  //     setNewNotify(0);
  //   }
  // }
  // useEffect(() => {
  //   setTimeout(() => {
  //     getNotifyCounts();
  //     console.log('5555');
      
  //   }, 2000);
  // }, []);


  return (
    <BrowserRouter>
      <RouterComponent /> {/* useNavigate is used in this component */}
    </BrowserRouter>
  );
}

export default App;
