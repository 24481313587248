import React, { useEffect, useState } from "react";
import Menus from "../../Menus";
import Footers from "../../Footers";
import MenuTop from "../../MenuTop";
import Swal from "sweetalert2";
import { API_ROUTES } from "../../../../utils/constants";
import { useForm } from "react-hook-form";
import { confirmPasswordValidation, passwordValidation } from "../../../../utils/Input";
import PostHelper from "../../../../utils/PostHelper";


const ChangePassword = (props) => {
    const { capitalizeWords, objectToFormData, isAuthExpired, token, newNotify, getNotifyCounts, isOffcanvasActive, handleMenuToggle, notifications, fetchNotifys, isAdmins } = props;
    useEffect(() => {document.title = "Change Password | Daniezo"}, []);

    const {register, handleSubmit, reset, getValues, formState: {errors, isValid, isSubmitting}} = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        getNotifyCounts();
        fetchNotifys();
    }, []);


    const changePassword = async (data) => {
        try {
            // const response = await fetch(API_ROUTES.CHANGE_PASSWORD, {
            //     method: "POST",
            //     body: objectToFormData(data),
            //     headers: {'hashKey': token}
            // });
            const response = await PostHelper(API_ROUTES.CHANGE_PASSWORD, data, token)
            const responseJSON = await response.json();
            isAuthExpired(responseJSON); // check htmlFor expired login

            Swal.fire({
                title: 'Processing...',
                text: "Please wait a second for a response...",
                icon: 'success',
                showConfirmButton: false,
                confirmButtonColor: '#027937',
                cancelButtonColor: '#d33',
            });

            if(responseJSON.status.toString() === "200"){
                const responseDetails = responseJSON.data;
                const userData1 = {};
                userData1.isLoggedIn = true;
                userData1.responseDetails = responseDetails;
                localStorage.setItem('userDataDani', JSON.stringify(userData1));

                Swal.fire({
                    title: "Successful",
                    text: "Your password has been updated",
                    icon: "success",
                    timer: 4000
                });
                setTimeout(() => {
                    reset();
                }, 500);
            }else{
                if(responseJSON.status.toString() === "500"){
                    Swal.fire({
                        title: "Error",
                        text: responseJSON.required_fields,
                        icon: "error",
                    });
                    return;
                }
                Swal.fire({
                    title: "Error",
                    text: responseJSON.message,
                    icon: "error",
                });
            }
        } catch (error) {
            if(error.response){
                Swal.fire({
                    title: "Error",
                    text: error.response,
                    icon: "error",
                });
            }
        }
    }
    


    return (
        <>
            <div className="container-scroller">
                <MenuTop isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />
                
                <div className="container-fluid page-body-wrapper">
                    <Menus isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />

                    <div className="main-panel">
                        <div className="content-wrapper p-xs-0">
                            <div className="row grid-margin mt--10 mt-xs-0">
                                <div className="col-sm-12 mb-xs-40">
                                    <div className="table-responsive_">

                                        <div className="col-12">
                                            <h2 className="mt-20 mt-xs-30 mb-30 mb-xs-30">Change Password</h2>
                                        </div>



                                        <div className="col-xl-6 col-lg-8 col-sm-10 p-xs-5">
                                            <div className="card_ forms-sample passwords pl-xs-15 pr-xs-15">
                                                <form className="mt-10" onSubmit={handleSubmit(changePassword)}>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputUsername1">Old Password</label>
                                                        {errors.old_password && <label className="errors">{errors.old_password?.message}</label>}

                                                        <input className="form-control" id="pass1" name="old_password" type="password" placeholder="Enter Your Old Password"
                                                        {...register("old_password", { 
                                                            required: "Enter your old password"
                                                            })}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputPassword1">New Password</label>
                                                        {errors.new_password && <label className="errors">{errors.new_password?.message}</label>}

                                                        <input className="form-control" id="pass2" type="password" name="new_password" placeholder="Enter Your New Password" 
                                                        {...register("new_password", {
                                                        ...passwordValidation
                                                        })} />
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputConfirmPassword1">Confirm Password</label>
                                                        {errors.confirm_password && <label className="errors">{errors.confirm_password?.message}</label>}

                                                        <input className="form-control" id="pass3" type="password" placeholder="Confirm Your New Password" 
                                                        {...register("confirm_password", {
                                                            ...confirmPasswordValidation,
                                                            validate: (value) => {
                                                                const new_pass = getValues("new_password");
                                                                if(value === new_pass){
                                                                    return true;
                                                                }else{
                                                                    return "Passwords do not match"
                                                                }
                                                            },
                                                        })}
                                                        />
                                                    </div>

                                                    <button className="btn btn-primary" type="submit" disabled={isSubmitting || !isValid} style={{ opacity: (isSubmitting || !isValid) ? '0.5' : 1 }}>Change Password</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <Footers />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChangePassword;