import { Navigate } from "react-router-dom";
import React from "react";

function PrivateRoutes({ children, redirectTo }) {
    const userData = localStorage.getItem('userDataDani');
    const userObj = JSON.parse(userData);

    // console.log(userObj);

    const isAuthenticated = { 'isLoggedIn': userObj !== null ? userObj.isLoggedIn : false }
    return isAuthenticated.isLoggedIn ? children : <Navigate to={redirectTo} />;
}

export default PrivateRoutes;