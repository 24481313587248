import React, { useEffect, useState } from "react";
import Menus from "../../Menus";
import Footers from "../../Footers";
import MenuTop from "../../MenuTop";
import Swal from "sweetalert2";
import { API_ROUTES } from "../../../../utils/constants";
import { useForm } from "react-hook-form";
import { confirmPasswordValidation, passwordValidation } from "../../../../utils/Input";
import { useNavigate } from "react-router-dom";
import PostHelper from "../../../../utils/PostHelper";

const GeneralSettings = (props) => {
    const { capitalizeWords, objectToFormData, isAuthExpired, token, newNotify, getNotifyCounts, isOffcanvasActive, handleMenuToggle, notifications, fetchNotifys, isAdmins } = props;
    useEffect(() => {document.title = "General Settings | Daniezo"}, []);

    const userData = JSON.parse(localStorage.getItem('userDataDani'));

    const is2Fa = userData?.responseDetails?.enable2fa ? userData?.responseDetails?.enable2fa : 0;
    const is2FaStaff = userData?.responseDetails?.enable2fa_staff ? userData?.responseDetails?.enable2fa_staff : 0;

    const [is2FA, setIs2FA] = useState(is2Fa);
    const [is2FAStaff, setIs2FAStaff] = useState(is2FaStaff);

    const navigate = useNavigate();

    useEffect(() => {
        getNotifyCounts();
        fetchNotifys();
    }, []);



    const goToChangePassword = () => {
        navigate('/change-password/');
    }

    //isAdmins.includes("can add admin") || isAdmins === "00")
    const enable2FA = async (status1, status2, e) => {
        let actn = "";
        const result = await Swal.fire({
            title: `Select Action`,
            text: 'Only the super admin can do this',
            icon: 'question',
            iconHtml: '?',
            showCancelButton: isAdmins === "00" && true,
            confirmButtonColor: 'green',
            cancelButtonColor: '#069',
            confirmButtonText: `${is2FA === 0 ? 'Enable' : 'Disable'} 2FA for yourself`,
            cancelButtonText: `${(is2FAStaff === 0) ? 'Enable' : 'Disable'} 2FA for all staff`,
        });
        if (result.isConfirmed) {
            actn = "admin";
        }else if (result.dismiss === Swal.DismissReason.cancel){ // if (result.dismiss === Swal.DismissReason.cancel)
            actn = "staff";
        }
        if(actn !== ""){
            Swal.fire({
                title: 'Processing...',
                text: "Please wait a second for a response...",
                icon: 'success',
                showConfirmButton: false,
                confirmButtonColor: '#027937',
                cancelButtonColor: '#d33',
            });
            try {
                const dataString = { 
                    action: actn,
                    status: status1,
                    staff_status: status2,  
                };
                // const response = await fetch(API_ROUTES.ENABLE2FA, {
                //     method: 'POST',
                //     body: objectToFormData(dataString),
                //     headers: {'hashKey': token}
                // });
                const response = await PostHelper(API_ROUTES.ENABLE2FA, dataString, token)
                const responseJSON = await response.json();
                isAuthExpired(responseJSON); // check for expired login
    
                if(responseJSON.status.toString() === "200"){
                    Swal.fire({
                        title: "Successful",
                        html: responseJSON.message,
                        icon: "success",
                        confirmButtonColor: '#393472',
                    }); 
                    userData.responseDetails.enable2fa = status1 === 0 && actn === "admin" ? 1 : 0;
                    userData.responseDetails.enable2fa_staff = status2 === 0 && actn === "staff" ? 1 : 0;
                    localStorage.setItem('userDataDani', JSON.stringify(userData));

                    setIs2FA(userData.responseDetails.enable2fa);
                    setIs2FAStaff(userData.responseDetails.enable2fa_staff);
                    return;
                } else {
                    Swal.fire({
                        title: 'Error',
                        html: responseJSON.message || 'An error occurred during the POST request.',
                        icon: 'error',
                    });
                }
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    text: error.message || 'An error occurred during the POST request.',
                    icon: 'error',
                });
            }
        }
    }
    


    return (
        <>
            <div className="container-scroller">
                <MenuTop isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />
                
                <div className="container-fluid page-body-wrapper">
                    <Menus isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />

                    <div className="main-panel">
                        <div className="content-wrapper p-xs-15">
                            <div className="row grid-margin mt--10 mt-xs-0">
                                <div className="col-sm-12 mb-xs-40">
                                    <div className="table-responsive_">

                                        <div className="col-12">
                                            <h2 className="mt-20 mt-xs-30 mb-30 mb-xs-30">General Settings</h2>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-4 col-sm-6 mb-sm-20">
                                                <div className="forms-sample text-center">
                                                    <div className="pb-20" style={{ fontWeight:'600' }}>Update your security by enabling 2FA on your device</div>
                                                    <button className="btn btn-block btn-primary" onClick={(e) => enable2FA(is2Fa, is2FaStaff, e)} type="button">Enable/Disable 2FA</button>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-sm-6 mb-sm-20">
                                                <div className="forms-sample text-center">
                                                    <div className="pb-20" style={{ fontWeight:'600' }}>Click to update your password to a stronger one</div>
                                                    <button className="btn btn-block btn-primary" onClick={goToChangePassword} type="button">Change Password</button>
                                                </div>
                                            </div>

                                            {/* <div className="col-lg-4 col-sm-6 mb-sm-20">
                                                <div className="forms-sample text-center">
                                                    <div className="pb-20" style={{ fontWeight:'600' }}>xxxxxxx xxxxxxxx xxxxx xxxx xxxxxxxx xxxxxx xxx x xxxxxxxx</div>
                                                    <button className="btn btn-block btn-primary" type="button">xxxxxxxxxxx</button>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <Footers />
                    </div>
                </div>
            </div>
        </>
    );
}

export default GeneralSettings;