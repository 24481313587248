import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_ROUTES } from "../../utils/constants";
import TimeAgo from "./TimeAgo";


export default function MenuTop(props) {
    const { isOffcanvasActive, handleMenuToggle, newNotify, notifications, isAdmins } = props;
    const navigate = useNavigate();
    const userData = localStorage.getItem('userDataDani');
    const userObj = JSON.parse(userData);
    // const [adminDetails, setAdminDetails] = useState(userObj);

    // console.log(userObj);
   
    const logout = () => {
        localStorage.removeItem('userDataDani');
        localStorage.removeItem('admin_priv');
        localStorage.removeItem('admin_roles');
        navigate('/login');
    }

    const capitalizeWords = (string) => {
        const words = string.split(' ');
        const capitalizedWords = words.map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        });
        return capitalizedWords.join(' ');
    }
    

    return (
        <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                <Link className="navbar-brand brand-logo" to="/dashboard/"><img src={`${API_ROUTES.API_URL}/assets/images/daniezo.png`} alt="logo" /></Link>
                <Link className="navbar-brand brand-logo-mini" to="/dashboard/"><img src={`${API_ROUTES.API_URL}/assets/images/favicon.png`} alt="logo" /></Link>
            </div>
            <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
                <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
                    <span className="mdi mdi-menu"></span>
                </button>
                <ul className="navbar-nav navbar-nav-right">
                    <li className="nav-item dropdown">
                        <a className="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#" data-toggle="dropdown">
                        <i className="mdi mdi-bell-outline mx-0"></i>
                        {
                            parseInt(newNotify) > 0 && 
                            <span className="new_notify new_notify_top">{parseInt(newNotify) > 9 ? "9+" : newNotify}</span>
                        }
                        </a>

                        <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-20" aria-labelledby="notificationDropdown">
                            <div className="inner_menu">
                                <a className="dropdown-item">
                                    <p className="mb-0 font-weight-normal float-left">You have {newNotify} new notifications
                                    </p>
                                    <Link to="/notifications" style={{ color:'#5f6be3' }}>
                                        <span className="badge badge-pill badge-warning float-right">View all</span>
                                    </Link>
                                </a>

                                {
                                    notifications.length !== 0 && notifications
                                    .filter(notification => notification.is_read === 0)
                                    .map((notification, index) => {
                                        const names = notification?.userDetails?.firstname !== null || notification?.userDetails?.firstname !== "" ? notification?.userDetails?.firstname : notification?.userDetails?.email;
                                        return (
                                            <div key={notification.id}>
                                                <div className="dropdown-divider"></div>
                                                <Link className="dropdown-item preview-item" to="/notifications/">
                                                    <div className="preview-thumbnail">
                                                        {/* <div className="preview-icon bg-success">
                                                            <i className="mdi mdi-information mx-0"></i>
                                                        </div> */}
                                                        <img className="img-sm rounded-circle" src={notification?.userDetails?.profile_photo !== null ? `${API_ROUTES.SERVER_ENDPOINT}/assets/profile_photo/${notification?.userDetails?.profile_photo}` : '../assets/images/avatar.png'} />
                                                    </div>
                                                    <div className="preview-item-content">
                                                        <h6 className="preview-subject font-weight-medium ellipsis">
                                                            <b style={{ color:'#5f6be3' }}>{names}</b> {notification.description}
                                                        </h6>
                                                        <p className="font-weight-light small-text mb-0">
                                                            <TimeAgo createdAt={notification.created_at} />
                                                        </p>
                                                    </div>
                                                </Link>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </li>

                    {/* <li className="nav-item dropdown">
                        <a className="nav-link count-indicator dropdown-toggle" id="messageDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
                        <i className="mdi mdi-email-outline mx-0"></i>
                        <span className="count"></span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="messageDropdown">
                        <div className="dropdown-item">
                            <p className="mb-0 font-weight-normal float-left">You have 7 unread mails
                            </p>
                            <span className="badge badge-info badge-pill float-right">View all</span>
                        </div>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item preview-item">
                            <div className="preview-thumbnail">
                                <img src="images/face4.jpg" alt="image" className="profile-pic" />
                            </div>
                            <div className="preview-item-content flex-grow">
                            <h6 className="preview-subject ellipsis font-weight-medium">David Grey
                                <span className="float-right font-weight-light small-text">1 Minutes ago</span>
                            </h6>
                            <p className="font-weight-light small-text mb-0">
                                The meeting is cancelled
                            </p>
                            </div>
                        </a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item preview-item">
                            <div className="preview-thumbnail">
                                <img src="images/face2.jpg" alt="image" className="profile-pic" />
                            </div>
                            <div className="preview-item-content flex-grow">
                            <h6 className="preview-subject ellipsis font-weight-medium">Tim Cook
                                <span className="float-right font-weight-light small-text">15 Minutes ago</span>
                            </h6>
                            <p className="font-weight-light small-text mb-0">
                                New product launch
                            </p>
                            </div>
                        </a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item preview-item">
                            <div className="preview-thumbnail">
                                <img src="images/face3.jpg" alt="image" className="profile-pic" />
                            </div>
                            <div className="preview-item-content flex-grow">
                            <h6 className="preview-subject ellipsis font-weight-medium"> Johnson
                                <span className="float-right font-weight-light small-text">18 Minutes ago</span>
                            </h6>
                            <p className="font-weight-light small-text mb-0">
                                Upcoming board meeting
                            </p>
                            </div>
                        </a>
                        </div>
                    </li> */}


                    <li className="nav-item nav-profile dropdown">
                        <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">
                            <img src={`${API_ROUTES.API_URL}/assets/images/avatar.png`} alt="profile" /> {userObj?.responseDetails?.firstname ? capitalizeWords(userObj?.responseDetails?.firstname) : ''}
                        </a>
                        <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
                        <Link className="dropdown-item" to="/general-settings/">
                            <i className="mdi mdi-settings text-primary"></i>
                            Settings
                        </Link>
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="/login" onClick={logout}>
                            <i className="mdi mdi-logout text-primary"></i>
                            Logout
                        </Link>
                        </div>
                    </li>
                </ul>
                <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={handleMenuToggle} data-toggle="offcanvas">
                    <span className="mdi mdi-menu"></span>
                </button>

            </div>
        </nav>
    );
}
