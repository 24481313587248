import React from "react";

export default function Footers(){
    return (
        <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
                <span className="text-center text-sm-left d-block d-sm-inline-block">Copyright © 2023 <a href="https://daneizo.com/" target="_blank">Daneizo</a>. All rights reserved.</span>
            </div>
        </footer>
    );
}