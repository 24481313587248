import CryptoJS from "crypto-js";


const encryptData = (data) => { 
    // console.log('data before encryption',data)
    const secretKey = process.env.REACT_APP_ENCRYPTION_KEY ;
    const ivkey = process.env.REACT_APP_IV_KEY
    const iv = CryptoJS.enc.Hex.parse(ivkey); // 32 hex characters

    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), CryptoJS.enc.Utf8.parse(secretKey), {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    });

    // console.log('data after encryption',encrypted.toString())
    return encrypted.toString();
  };

 export default encryptData; 